import React, {useState} from "react";
import {css} from "@emotion/react"
import { Helmet } from "react-helmet";

const QUESTIONS = [
  {
    prompt: "You really want to chill on your farm, but the people are asking you to lead the United States of America",
    img: "mtvernon.jpeg",
    options: [
      {text: "Ride away on a cow", value: 5},
      {text: "Reluctantly accept", value: 16},
      {text: "Tell them this was your master plan the whole time and now you’re going to become an evil tyrant, then laugh to make them think you were joking", value: 0},
    ]
  },
  {
    prompt: "Congrats, you’re now the leader of the United States of America, what should we call you?",
    img: "palp.jpeg",
    options: [
      {text: 'Your Highness', value: 4},
      {text: "The Supreme Ultimate Leader", value: 0},
      {text: 'President', value: 16},
    ]
  },
  {
    prompt: "Hamilton and Jefferson are at it again. Hamilton wants to create a national bank, and Jefferson thinks this is stupid",
    img: "cabinetbattle.jpeg",
    options: [
      {text: "Tell them both to stfu", value: 5},
      {text: "Side with Jefferson", value: 0},
      {text: "Side with Hamilton", value: 16},
    ]
  },
  {
    prompt: "You’re really tired of this job, and your term is over, but everyone wants you to be president again",
    img: "beg.jpeg",
    options: [
      {text: "Sigh, okay", value: 16},
      {text: "I’ll do it if Jefferson and Hamilton stfu", value: 16},
      {text: "Ride away on a cow", value: 0},
    ]
  },
  {
    prompt: "The French are having a revolution, should you help them as they helped you?",
    img: "frenchr.jpg",
    options: [
      {text: "No I’m too poor", value: 16},
      {text: "Hell yeah", value: 0},
      {text: "Hamilton, drop the statement of neutrality", value: 16},
    ]
  },
  {
    prompt: "Some alcoholics are really mad that you’re taxing whiskey and they started a rebellion",
    img: "party.jpeg",
    options: [
      {text: "Go have a drink with them and talk about it", value: 5},
      {text: "March on them with 13,000 soldiers", value: 10},
      {text: "Ignore them", value: 0},
    ]
  },
  {
    prompt: "You’ve had enough and are finally going to retire. What is your farewell speech like?",
    img: "farewell.jpg",
    options: [
      {text: "Deez Nuts", value: 70},
      {text: "Warn people about the deep state and fake news media", value: 0},
      {text: "Speak out against partisanship, political parties, and foreign intervention", value: 10},
    ]
  },
]

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

const USPresidentSimulator = () => {
  const [questionIndex, setQuestionIndex]= useState(0)
  const [prob, setProb] = useState(0)
  const [diff, setDiff] = useState(0)
  const chances = getRandomInt(100)
  const highApproval = prob > 80

  const onSelectOption = (option) => {
    setDiff(option.value)
    setProb(prob + option.value)
    setQuestionIndex(questionIndex+1)
  }

  return (
    <div css={css`
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      padding-bottom: 50px;
    `}>
      <h1 css={css`
        margin-bottom: 10px;
        text-align: center;
      `}>American President Simulator</h1>
      <img css={css`
        height: 100px;

      `} src="/flag13.png" alt="USA Flag (13 states)"></img>

      <h3 css={css`margin-bottom: 5px`}>Your goal is to get a high approval rating</h3>
      <label for="file">Approval Rating ({prob}%)</label>
<progress id="file" value={prob} max="100"> {prob}% </progress>
<div css={css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`}>

  {questionIndex < QUESTIONS.length ? (
    <>
      <h2 css={css`
        text-align: center;
      `}>{QUESTIONS[questionIndex].prompt}</h2>

  <img css={css`
    max-height: 100px;
    margin-top: 15px;
  `} src={QUESTIONS[questionIndex].img} alt="something funny"></img>
  <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
  
  `}>
  {QUESTIONS[questionIndex].options.map((option) => (
    <button css={css`
    margin-top: 20px;
    font-size: 18px;
    padding: 6px;
    cursor: pointer;
    width: 350px;
    word-wrap: break-word;
    `}
    onClick={() => onSelectOption(option)}
    >
     { option.text}
      </button>
  ))}
  </div>
  </>
  ) : highApproval ?  (
    <div css={css`text-align: center;`}>
      <h2>You have successfully run the country</h2>
      <img css={css`
        max-width: 400px;
      `} src="/washington.jpeg"/>
      <h3>You'll be remembered forever.</h3>
    </div>
  ) : <div css={css`text-align: center;`}>
      <h2>The people did not approve of you</h2>
      <img css={css`
       max-width: 400px;
      `} src="/sadwashington.jpeg"/>
      <h3>Sad life.</h3>
    </div>}

</div>
    </div>
  )
}
  export default USPresidentSimulator;